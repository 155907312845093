import {MatTooltip} from '@angular/material/tooltip';
import {Directive, ElementRef, OnInit} from '@angular/core';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: '[matTooltip][tooltipShowIfTruncated]'
})
export class ShowIfTruncatedDirective implements OnInit {

    constructor(
        private matTooltip: MatTooltip,
        private elementRef: ElementRef<HTMLElement>
    ) {
    }

    public ngOnInit(): void {
        // Wait for DOM update
        setTimeout(() => {
            const element = this.elementRef.nativeElement;
            this.matTooltip.disabled = element.scrollWidth <= element.clientWidth;
        });
    }
}
