import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, defer, Observable, of, tap, throwError} from 'rxjs';
import {UserInfo} from '../../auth/models/User';
import {AddressModel} from '../models/address.model';
import {CommunicationResponseModel} from '../models/communication-response.model';
import {CommunicationRequestModel} from '../models/communication-request.model';
import {CommunicationEventsModel} from '../models/communication-events.model';
import {GenericResponse} from '../../auth/models/generic.response';
import {ArchivedOrdersModels, OrderItem, OrdersModels, ProcessingInfo} from '../models/orders-models';
import {ISector} from '../interfaces/ISector';
import {ISocietyResp} from '../interfaces/ISocietyResp';
import {IDropdownData} from '../interfaces/IDropdownData';
import {catchError, map} from 'rxjs/operators';
import {ICashback} from '../interfaces/ICashback';
import {InfoFileUploadModel} from '../models/info-file-upload.model';
import {
    UploadOrderFileData
} from '../components/orders/uploading-file-order/upload-file-step2/upload-file-step2.component';
import {AzureRequest} from "../models/azure-request.model";
import {UploadingOrderFileModel} from "../../../shared/models/uploading-order-file-model";

export class Dropdown {
    id: string;
    label: string;
}

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    public canNextButtonGoOn = new BehaviorSubject<boolean>(true);
    public sharedOrderData = new BehaviorSubject<ProcessingInfo>(null);
    private _provinces: Array<Dropdown>;
    private _orderItemNumber: number;
    private readonly ENDPOINT_V1: string = `${environment.addressPressupAPIDEM}${environment.api_v1}`;
    private readonly ORDER_ITEM_ID_PARAM = 'orderItemId';

    constructor(private http: HttpClient) {
    }

    public updateUserInfo(userInfo: UserInfo): Observable<UserInfo> {
        return this.http.put<UserInfo>(`${this.ENDPOINT_V1}/User`, userInfo);
    }

    public getUserAddresses(): Observable<Array<AddressModel>> {
        return this.http.get<Array<AddressModel>>(`${this.ENDPOINT_V1}/Address/GetAll`);
    }

    public getAddressesById(id: string): Observable<AddressModel> {
        return this.http.get<AddressModel>(`${this.ENDPOINT_V1}/Address/${id}`);
    }

    public getUserCommunication(): Observable<Array<CommunicationResponseModel>> {
        return this.http.get<Array<CommunicationResponseModel>>(`${this.ENDPOINT_V1}/Comunication`);
    }

    public getUserCommunicationEvent(): Observable<Array<CommunicationEventsModel>> {
        return this.http.get<Array<CommunicationEventsModel>>(`${this.ENDPOINT_V1}/Comunication/GetAllEvent`);
    }

    public createNewCommunication(data: CommunicationRequestModel): Observable<CommunicationResponseModel> {
        return this.http.post<CommunicationResponseModel>(`${this.ENDPOINT_V1}/Comunication`, data);
    }

    public deleteCommunication(data: CommunicationRequestModel): Observable<GenericResponse> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: {
                email: data?.email,
                eventId: data?.eventId,
            },
        };
        return this.http.delete<GenericResponse>(`${this.ENDPOINT_V1}/Comunication`, options);
    }

    // Order
    public getOrders(page: number, pageSize: number, filter: string): Observable<OrdersModels> {
        return this.http.get<OrdersModels>(`${this.ENDPOINT_V1}/Order/GetAll?page=${page}&pageSize=${pageSize}&filter=${filter}`);
    }

    public getArchivedOrders(page: number, pageSize: number, filter: string): Observable<ArchivedOrdersModels> {
        const allFilter = this.setArchivedOrderFilters(page, pageSize, filter);
        return this.http.get<ArchivedOrdersModels>(`${this.ENDPOINT_V1}/Order/GetArchivedOrders` + allFilter);
    }

    public getOrderById(orderId: number): Observable<Array<OrderItem>> {
        return this.http.get<Array<OrderItem>>(`${this.ENDPOINT_V1}/Order/GetOrderItems?orderId=${orderId}`);
    }

    public getOrderItemById(orderItemId: number): Observable<ProcessingInfo> {
        return this.http.get<ProcessingInfo>(`${this.ENDPOINT_V1}/Order/GetOrderItem?orderItemId=${orderItemId}`);
    }

    // per richiamarti le api seguenti ti devi chiamare get all by user (this.getOrders) di mungari
    // poi ti prendi orderid dalla risposta lo passi e ti chiami Order/GetOrderItems (this.getOrderById) e poi dalla risposta
    // utilizzi orderItemId per passarli come parametro alle api di BookProof/PdfReview
    // se la risposta di Order/GetOrderItems contiene confirmBookProof = true devi utilizzare le api /Order/BookProof/
    // se la risposta di Order/GetOrderItems contiene confirmPDfReview = true devi utilizzare le api /Order/PdfReview/

    // secondo quando ho capito i book proof te le dovresti chiamare quando pressup ha inviato un campione a casa del cliente
    public confirmFileBookProof(orderItemId: number): Observable<GenericResponse> {
        const param = new HttpParams();
        param.append(this.ORDER_ITEM_ID_PARAM, orderItemId);
        return this.http.patch<GenericResponse>(`${this.ENDPOINT_V1}/Order/BookProof/ConfirmFile`, {param});
    }

    public uploadNewFileBookProof(orderItemId: number): Observable<GenericResponse> {
        const param = new HttpParams();
        param.append(this.ORDER_ITEM_ID_PARAM, orderItemId);
        return this.http.patch<GenericResponse>(`${this.ENDPOINT_V1}/Order/BookProof/UploadNewFile`, {param});
    }

    public cancelOrderBookProof(orderItemId: number): Observable<GenericResponse> {
        const param = new HttpParams();
        param.append(this.ORDER_ITEM_ID_PARAM, orderItemId);
        return this.http.patch<GenericResponse>(`${this.ENDPOINT_V1}/Order/BookProof/CancelOrder`, {param});
    }

    // i pdf dovresti chiamarteli quando pressup ha inviato una copia di esempio per email del ordine
    public confirmFilePdf(orderItemId: number): Observable<GenericResponse> {
        const param = new HttpParams();
        param.append(this.ORDER_ITEM_ID_PARAM, orderItemId);
        return this.http.patch<GenericResponse>(`${this.ENDPOINT_V1}/Order/PdfReview/ConfirmFile`, {param});
    }

    public uploadNewFilePdf(orderItemId: number): Observable<GenericResponse> {
        const param = new HttpParams();
        param.append(this.ORDER_ITEM_ID_PARAM, orderItemId);
        return this.http.patch<GenericResponse>(`${this.ENDPOINT_V1}/Order/PdfReview/UploadNewFile`, {param});
    }

    public getInfoOnFileUpload(orderItemId: number): Observable<InfoFileUploadModel> {
        return this.http.get<InfoFileUploadModel>(`${this.ENDPOINT_V1}/Upload/GetInfo?orderitemid=${orderItemId}`);
    }

    public uploadFileAzure(files: UploadingOrderFileModel, azureRequest: AzureRequest): Observable<unknown> {
        const formData: FormData = new FormData();
        const fileList = files.mappingDataFileAzure.map(el => el.file?.selectedFile);
        fileList?.forEach(file => {
            formData.append('files', file);
        });
        formData.append('payload', JSON.stringify(azureRequest));
        return this.http.post<unknown>(`${environment.addressPressupBackofficeAPI}${environment.api_v1}/azure`, formData);
    }

    public getAzureSas(): Observable<InfoFileUploadModel> {
        return this.http.get<InfoFileUploadModel>(`${this.ENDPOINT_V1}/Upload/GetAzureSas`);
    }

    public deleteAddress(id: number): Observable<GenericResponse> {
        return this.http.delete<GenericResponse>(`${this.ENDPOINT_V1}/Address?id=${id}`);
    }

    public setFileOrder(uploadOrderFileData: UploadOrderFileData): Observable<InfoFileUploadModel> {
        return this.http.post<InfoFileUploadModel>(`${this.ENDPOINT_V1}/Upload/SetFileOrdine`, uploadOrderFileData);
    }

    public saveAddress(address: AddressModel): Observable<AddressModel> {
        return this.http.post<AddressModel>(`${this.ENDPOINT_V1}/Address`, address);
    }

    public updateAddress(address: AddressModel): Observable<AddressModel> {
        return this.http.put<AddressModel>(`${this.ENDPOINT_V1}/Address`, address);
    }

    public getSector(): Observable<ISector> {
        return this.http.get<ISector>(`${this.ENDPOINT_V1}/User/GetBusinessSectors`);
    }

    public getSociety(): Observable<ISocietyResp> {
        return this.http.get<ISector>(`${this.ENDPOINT_V1}/User/GetCustomerTypes`);
    }

    public getHowKnowUs(): Observable<IDropdownData> {
        return this.http.get<IDropdownData>(`${this.ENDPOINT_V1}/User/GetHowKnowUsOptions`);
    }

    public getCountry(): Observable<IDropdownData> {
        return this.http.get<IDropdownData>(`${this.ENDPOINT_V1}/Address/GetCountry`);
    }

    public getProvince(): Observable<IDropdownData> {
        return this.http.get<IDropdownData>(`${this.ENDPOINT_V1}/Address/GetState`);
    }

    public getProvinceShared(): Observable<Array<Dropdown>> {
        return defer(() => {
            if (!this.getProvinces()) {
                return this.getProvince().pipe(
                    map((response: IDropdownData) => this.buildArrayForDropdown(response)),
                    tap((response: Array<Dropdown>) => this.setProvinces(response)),
                    catchError((err) => {
                        console.error('error during get province', err);
                        return throwError(() => err);
                    }),
                );
            } else {
                return of(this._provinces);
            }
        });
    }

    private buildArrayForDropdown(data: any): { id: string; label: unknown }[] {
        return Object.entries(data).map(([id, label]) => ({id, label}));
    }

    public getCapByStateCode(code: string): Observable<IDropdownData> {
        return this.http.get<IDropdownData>(`${this.ENDPOINT_V1}/Address/GetZipCode/${code}`);
    }

    public getLocationByCap(code: string): Observable<IDropdownData> {
        return this.http.get<IDropdownData>(`${this.ENDPOINT_V1}/Address/GetCity/${code}`);
    }

    public getProvinces(): Array<Dropdown> {
        return this._provinces;
    }

    public setProvinces(value: Array<Dropdown>) {
        this._provinces = value;
    }

    public isButtonChanged(): Observable<boolean> {
        return this.canNextButtonGoOn.asObservable();
    }

    public getNextButtonValue(): boolean {
        return this.canNextButtonGoOn.value;
    }

    public setNextButton(value: boolean): void {
        this.canNextButtonGoOn.next(value);
    }

    public isSharedOrderDataChanged(): Observable<ProcessingInfo> {
        return this.sharedOrderData.asObservable();
    }

    public getSharedOrderData(): ProcessingInfo {
        return this.sharedOrderData.value;
    }

    public setSharedOrderData(value: ProcessingInfo): void {
        this.sharedOrderData.next(value);
    }

    public getCashbackData(): Observable<ICashback> {
        return this.http.get<ICashback>(`${this.ENDPOINT_V1}/User/GetCaskback`);
    }

    public getOrderItemNumber(): number {
        return this._orderItemNumber;
    }

    public setOrderItemNumber(value: number) {
        this._orderItemNumber = value;
    }

    // PRIVATE METHODS

    private setArchivedOrderFilters(page?: number, pageSize?: number, filter?: string): string {
        let allFilter = '';
        allFilter = page ? allFilter + `?page=${page}` : allFilter + '';
        allFilter = pageSize ? allFilter + `&pageSize=${pageSize}` : allFilter + '';
        allFilter = filter ? allFilter + `&filter=${filter}` : allFilter + '';
        return allFilter;
    }
}

