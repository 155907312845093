<!-- header start -->
<header class="sticky header-2 header-6" [ngClass]="class" [class.fixed]="stick && sticky">
    <div class="mobile-fix-option"></div>
    <div>
        <div class="row ps-2rem pe-2rem">
            <div class="col-sm-12 padding-start-0-responsive">
                <div class="main-menu border-section border-top-0">
                    <div class="menu-left padding-start-0-responsive">
                        <app-left-menu></app-left-menu>
                        <div class="brand-logo">
                            <a routerLink="/home" id="headerlogo">
                                <img [src]="themeLogo" class="img-fluid dim-logo clickable" alt="logo">
                            </a>
                        </div>

                        <div class="d-flex position-relative ms-3 search-bar-standard">
                            <app-ng-select [name]="'searchItem'"
                                           [loading]="isSearchingItem"
                                           [notFound]="'HEADER.NO_RESULT'"
                                           [items]="itemsStructure"
                                           [bindValue]="'_id'"
                                           [virtualScroll]="false"
                                           [bindLabel]="'name_it'"
                                           [placeholder]="'HEADER.PLACEHOLDER_SEARCH'"
                                           [isOptionLabelToTranslate]="true"
                                           [selectedItem]="selectedItemStructure"
                                           [searchingCallback]="searchingCallback"
                                           [minCharForSearch]="3"
                                           [initLoadItems]="initLoadItemCallback"
                                           [itemTemplate]="itemMenu"
                                           [scrollToEndCallback]="loadNextPage"
                                           [returnFullObjectOnChanged]="true"
                                           [clearable]="false"
                                           [hideDropdownIfEmptyOptions]="true"
                                           [onKeyEnterPressed]="goSearchPage.bind(this)"
                                           [clearInputAfterSelectItem]="true"
                                           (selectedItemChangeEvent)="onSelectedItem($event)"
                                           (searchingParamsEvent)="changeVal($event)"
                                           (searchingEvent)="responseApi($event)"
                                           (closeEvent)="clearOptions()"
                                           (scrollToEndEvent)="concatItem($event)"
                                           (focusEvent)="clearOptions()"
                                           class="search-item"
                                           ngDefaultControl
                                           id="ng-select-search">
                            </app-ng-select>
                            <div class="position-absolute mic-icon-container">
                                <button class="btn search-bar-btn-mic h-100 d-flex justify-content-center align-items-center" (click)="speechRecognitions()">
                                    <img src="./assets/images/press-up/icone/home/icn_microfono.svg" class="img-fluid">
                                </button>
                            </div>
                            <div class="position-absolute search-icon-container">
                                <button class="btn search-bar-btn-search h-100" (click)="goSearchPage()">
                                    <mat-icon class="icon-search">search</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="menu-right pull-right">
                        <app-settings></app-settings>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="row search-bar-full-width mb-3">
                    <div class="m-0 p-0 d-flex position-relative">
                        <app-ng-select [name]="'searchItem'"
                                       [loading]="isSearchingItem"
                                       [notFound]="'HEADER.NO_RESULT'"
                                       [items]="itemsStructure"
                                       [bindValue]="'_id'"
                                       [virtualScroll]="false"
                                       [bindLabel]="'name_it'"
                                       [placeholder]="'HEADER.PLACEHOLDER_SEARCH'"
                                       [isOptionLabelToTranslate]="true"
                                       [selectedItem]="selectedItemStructure"
                                       [searchingCallback]="searchingCallback"
                                       [minCharForSearch]="3"
                                       [initLoadItems]="initLoadItemCallback"
                                       [itemTemplate]="itemMenu"
                                       [scrollToEndCallback]="loadNextPage"
                                       [returnFullObjectOnChanged]="true"
                                       [clearable]="false"
                                       [hideDropdownIfEmptyOptions]="true"
                                       [onKeyEnterPressed]="goSearchPage.bind(this)"
                                       [clearInputAfterSelectItem]="true"
                                       [inputText]="inputTextInSearch"
                                       (selectedItemChangeEvent)="onSelectedItem($event)"
                                       (searchingParamsEvent)="changeVal($event)"
                                       (searchingEvent)="responseApi($event)"
                                       (closeEvent)="clearOptions()"
                                       (scrollToEndEvent)="concatItem($event)"
                                       (focusEvent)="clearOptions()"
                                       class="search-item w-100 z-2"
                                       ngDefaultControl
                                       id="ng-select-search-large">
                        </app-ng-select>
                        <div class="position-absolute mic-icon-container z-3">
                            <button class="btn search-bar-btn-mic h-100" (click)="speechRecognitions()">
                                <img src="./assets/images/press-up/icone/home/icn_microfono.svg" class="img-fluid">
                            </button>
                        </div>
                        <div class="position-absolute search-icon-container z-3">
                            <button class="btn search-bar-btn-search h-100" (click)="goSearchPage()">
                                <mat-icon class="icon-search">search</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <div class="row ps-4 pe-4">
            <div class="col-lg-12">
                <div class="main-nav-center">
                    <app-menu></app-menu>
                </div>
            </div>
        </div>
    </div>
</header>

<ng-template #itemMenu let-itemStructure>
    <img *ngIf="itemStructure?.id_picture?.min" ngSrc="{{itemStructure?.id_picture?.min}}" placeholder
         width="70" height="70" class="img-fluid me-2"
         alt="{{itemStructure?.id_picture?.originalname}}" loading="lazy" fetchpriority="auto">
    {{itemStructure?.name_it}}
</ng-template>
