import {Item, Root, SubItem, SubItem2} from './MegaMenu';

export class Menu {
    column_nums?: number;
    path?: string;
    label_it?: string;
    label_en?: string;
    type?: string;
    megaMenu?: boolean;
    image?: string;
    active?: boolean;
    badge?: boolean;
    badgeText?: string;
    children?: Menu[];
    subItems?: Menu[][];
    tag_items?: TagItem[];
    root_id?: string;
    id_link?: string;
    level?: number;
    visibility?: boolean;

    constructor(column_nums?: number, path?: string, label_it?: string, label_en?: string, type?: string, megaMenu?: boolean, image?: string,
                active?: boolean, badge?: boolean, badgeText?: string, children?: Menu[], subItems?: Menu[][], tag_items?: TagItem[],
                root_id?: string, id_link?: string, level?: number) {
        this.column_nums = column_nums;
        this.path = path;
        this.label_it = label_it;
        this.label_en = label_en;
        this.type = type;
        this.megaMenu = megaMenu;
        this.image = image;
        this.active = active;
        this.badge = badge;
        this.badgeText = badgeText;
        this.children = children;
        this.subItems = subItems;
        this.tag_items = tag_items;
        this.root_id = root_id;
        this.id_link = id_link;
        this.level = level;
    }

    public static buildMenuFullPageFromBO(menuBO: Root): Menu[] {
        let isAllProduct: boolean;
        return menuBO?.items?.map(((item, index) => {
            isAllProduct = index === 0;
            const itemMenu = new Menu();
            itemMenu.badge = false;
            itemMenu.active = false;
            itemMenu.label_it = item.label_it;
            itemMenu.label_en = item.label_en;
            itemMenu.column_nums = item.column_nums;
            itemMenu.visibility = item.visibility;
            this.isNewItemFirstLevel(itemMenu, item);
            this.isLink(itemMenu, item);
            if (item.sub_items) {
                itemMenu.subItems = [];
                itemMenu.megaMenu = true;
                itemMenu.type = 'sub';
                let col = 0;
                item.sub_items.forEach((subMenu, index) => {
                    if (this.isVisibleSubMenu(isAllProduct, subMenu)) {
                        if (col !== subMenu.index_col) {
                            col = subMenu.index_col;
                            if (!itemMenu.subItems?.[col - 1]) {
                                itemMenu.subItems[col - 1] = [];
                            }
                        }
                        const newSubItem = new Menu();
                        newSubItem.active = false;
                        newSubItem.label_it = subMenu.label_it;
                        newSubItem.label_en = subMenu.label_en;
                        this.isUriLink(newSubItem, subMenu);
                        if (subMenu.sub_items.length > 0) {
                            newSubItem.children = [];
                            newSubItem.type = 'sub';
                            subMenu.sub_items.forEach(subSubMenu => {
                                if (subSubMenu.visibility) {
                                    const newSubSubItem = new Menu();
                                    newSubSubItem.label_it = subSubMenu.label_it;
                                    newSubSubItem.label_en = subSubMenu.label_en;
                                    newSubSubItem.path = subSubMenu.link;
                                    this.isNewItem(newSubSubItem, subSubMenu);
                                    this.hasTagItems(newSubSubItem, subSubMenu);
                                    this.isLink(newSubSubItem, subSubMenu);
                                    newSubItem.children.push(newSubSubItem);
                                }
                            });
                        }
                        itemMenu.subItems[col - 1][subMenu.index_row - 1] = newSubItem;
                        if (item.sub_items.length - 1 === index) {
                            const colToAchieve = item?.column_nums;
                            for (let i = 0; i <= colToAchieve - 1; i++) {
                                if (!itemMenu.subItems?.[i]) {
                                     itemMenu.subItems[i] = [];
                                }
                            }
                        }
                    }
                });
            }
            itemMenu.subItems = itemMenu?.subItems?.map(item => item && item?.filter(el => el !== null));
            return itemMenu;
        }));
    }

    public static buildMenuResponsivePageFromBO(menuBO: Root): Menu[] {
        let isAllProduct: boolean;
        return menuBO?.items?.map(((item, index) => {
            isAllProduct = index === 0;
            const itemMenu = new Menu();
            itemMenu.badge = false;
            itemMenu.active = false;
            itemMenu.label_it = item.label_it;
            itemMenu.label_en = item.label_en;
            itemMenu.column_nums = item.column_nums;
            itemMenu.visibility = item.visibility;
            this.isNewItemFirstLevel(itemMenu, item);
            this.isLink(itemMenu, item);
            if (item.sub_items) {
                itemMenu.children = [];
                itemMenu.megaMenu = true;
                itemMenu.type = 'sub';
                item.sub_items.forEach(subMenu => {
                    if (this.isVisibleSubMenu(isAllProduct, subMenu)) {
                        const newSubItem = new Menu();
                        newSubItem.active = false;
                        newSubItem.label_it = subMenu.label_it;
                        newSubItem.label_en = subMenu.label_en;
                        this.isUriLink(newSubItem, subMenu);
                        if (subMenu.sub_items) {
                            newSubItem.type = 'sub';
                            newSubItem.children = [];
                            subMenu.sub_items.forEach(subSubMenu => {
                                if (subSubMenu.visibility) {
                                    const newSubSubItem = new Menu();
                                    newSubSubItem.label_it = subSubMenu.label_it;
                                    newSubSubItem.label_en = subSubMenu.label_en;
                                    newSubSubItem.path = subSubMenu.link;
                                    this.isNewItem(newSubSubItem, subSubMenu);
                                    this.hasTagItems(newSubSubItem, subSubMenu);
                                    this.isLink(newSubSubItem, subSubMenu);
                                    newSubItem.children.push(newSubSubItem);
                                }
                            });
                        }
                        itemMenu.children.push(newSubItem);
                    }
                });
            }
            return itemMenu;
        }));
    }

    public static isLink(itemMenu: Menu, menuBO: Item | SubItem2) {
        if (menuBO?.link) {
            itemMenu.path = menuBO.link;
            itemMenu.type = 'link';
        }
    }

    public static isUriLink(itemMenu: Menu, menuBO: SubItem) {
        if (menuBO?.uri) {
            itemMenu.type = 'link';
            itemMenu.path = menuBO.uri;
        }
    }

    public static isNewItem(itemMenu: Menu, menuBO: SubItem2) {
        if (menuBO?.tag_new) {
            const currentDate = new Date();
            const endDate = new Date(menuBO?.tag_new_end_date);
            if (menuBO?.tag_new_end_date && currentDate > endDate) {
                itemMenu.badge = false;
            } else {
                itemMenu.badge = true;
                itemMenu.badgeText = 'new';
            }
        } else {
            itemMenu.badge = false;
        }
    }

    public static isNewItemFirstLevel(itemMenu: Menu, menuBO: Item) {
        if (menuBO?.tagNewActive) {
            const currentDate = new Date();
            const endDate = new Date(menuBO?.tag_new_end_date);
            if (menuBO?.tag_new_end_date && currentDate > endDate) {
                itemMenu.badge = false;
            } else {
                itemMenu.badge = true;
                itemMenu.badgeText = 'new';
            }
        } else {
            itemMenu.badge = false;
        }
    }

    public static hasTagItems(itemMenu: Menu, menuBO: SubItem2) {
        if (menuBO?.tag_items?.length > 0) {
            itemMenu.tag_items = menuBO?.tag_items;
        }
    }

    public static isVisibleSubMenu(allProduct: boolean, subItem: SubItem): boolean {
        if (allProduct) {
            return subItem?.visibility;
        } else {
            return subItem?.visibility || (!subItem?.visibility && subItem.sub_items.length > 0);
        }
    }
}

export class TagItem {
    fk_tag_item: string;
    label_it: string;
    icn_menu_path: ImagePath;
    icn_page_path: ImagePath;
    validity?: Date;
    visibility?: boolean;
    from?: string;
    to?: string;

    constructor(fk_tag_item?: string, label_it?: string, icn_menu_path?: ImagePath, icn_page_path?: ImagePath, validity?: Date,
                visibility?: boolean, from?: string, to?: string) {
        this.fk_tag_item = fk_tag_item;
        this.label_it = label_it;
        this.icn_menu_path = icn_menu_path;
        this.icn_page_path = icn_page_path;
        this.validity = validity;
        this.visibility = visibility;
        this.from = from;
        this.to = to;
    }
}

export class ImagePath {
    originalname: string;
    min: string;
    medium: string;
    large: string;
    original: string;
    _id: string;


    constructor(originalname?: string, min?: string, medium?: string, large?: string, original?: string, id?: string) {
        this.originalname = originalname;
        this.min = min;
        this.medium = medium;
        this.large = large;
        this.original = original;
        this._id = id;
    }
}
