export enum EstimateEnum {
    JOB_NAME = 'jobName',
    NUM_COPIES = 'numCopies',
    JOB_TYPE = 'jobType',
    PRODUCT = 'product',
    CLOSED_FORMAT = 'closedFormat',
    OPEN_FORMAT = 'openFormat',
    MEASURE_STRAPS = 'measureStraps',
    NUMBER_PAGE_COVER = 'numberPageCover',
    PRINT_COLOR = 'printColor',
    COVER_COLOR = 'coverColor',
    INTERNAL_PAPER_TYPE = 'internalPaperType',
    COVER_PAPER_TYPE = 'coverPaperType',
    BINDING = 'binding',
    LAMINATION = 'lamination',
    DESCRIPTION = 'description',
    NOMINATIVE = 'nominative',
    PHONE = 'phone',
    EMAIL = 'email'
}
