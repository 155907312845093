<div class="modal-header border-0">
    <div class="icon-box">
        <img [src]="icon" class="material-icons" alt="svg icon"/>
    </div>
</div>
<div class="modal-body">
    <h3 class="font-size-1-1-rem">{{ title | translate }}</h3>
    <p class="font-size-1-1-rem">{{ subTitle | translate }}</p>
</div>
<div class="modal-footer d-block text-center bg-footer">
    <a [tabIndex]="0"></a>
    @if (visibleCancelBtn) {
        <button class="btn btn-outline me-3 mb-3 default-dim-modals-button" type="button" id="cancelBtn"
                (click)="cancelAction()">{{ textCancelBtn | translate }}
        </button>
    }
    <button class="btn btn-solid mb-3 default-dim-modals-button" type="submit" (click)="successAction()"
            [tabIndex]="0">{{ textOkButton | translate }}
    </button>
</div>
