export enum SocietyTypeEnum {
    PERSONA_FISICA =  1 ,
    SS =  2 ,
    IMPRESA_INDIVIDUALE =  3 ,
    ASS =  4 ,
    SNC =  5 ,
    SAS =  6 ,
    SPA =  7 ,
    SAA =  8 ,
    SRL =  9 ,
    COOP =  10 ,
    ENTE_PUBBLICO =  11 ,
    SRLS =  12 ,
    AZIENDA_ESTERNA =  100 ,
}
